var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('a-page-header',{attrs:{"title":_vm.title}}),_c('div',{staticClass:"main-content"},[_c('a-tabs',{attrs:{"default-active-key":_vm.tabIndex,"animated":false},on:{"change":_vm.callback}},_vm._l((_vm.tabs),function(item){return _c('a-tab-pane',{key:item.key,attrs:{"tab":item.name}},[_c('a-button',{staticClass:"business",attrs:{"type":"primary"}},[_vm._v(" 添加业务 ")]),_c('my-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"pagination":false,"loading":_vm.loading},scopedSlots:_vm._u([{key:"action",fn:function(record){return [_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.$jump({
                  path: '/serviceItems',
                  query: {
                    bcid: record.bcid,
                    id: record.categoryId,
                    name: record.catename,
                    tabIndex:_vm.tabIndex
                  }
                })}}},[_vm._v(" 服务项目 ")]),(_vm.tabIndex == 0)?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.$jump({
                  path: '/serviceAddress',
                  query: {
                    bcid: record.bcid,
                    id: record.categoryId,
                    name: record.catename
                  }
                })}}},[_vm._v(" 服务地址栅栏 ")]):_vm._e()]}}],null,true)})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }