<template>
  <!-- 业务线管理 -->
  <div class="container">
    <a-page-header :title="title" />
    <!-- v-for="item in dataSource" :key="item.id" -->
    <div class="main-content">
      <a-tabs
        :default-active-key="tabIndex"
        :animated="false"
        @change="callback"
      >
        <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.name">
          <!-- <div v-if="tabIndex == 0"> -->
          <!-- <div class="main-content-header"> -->
          <!-- <div class="main-title">主线业务</div> -->
          <!-- <div class="item">
          <div class="title">
            <span class="title-1">{{ item.name }}</span>
          </div>
        </div> -->
          <!-- <div class="item">
          <a-button
            type="primary"
            @click="
              $jump({
                path: '/serviceAdd',
                query: {
                  name: '家庭单品'
                }
              })
            "
          >
            添加服务类型
          </a-button>
        </div> -->
          <!-- @click="onAddSpecification" -->
          <a-button type="primary" class="business">
          添加业务
        </a-button>
          <!-- </div> -->

          <my-table
            :columns="columns"
            :data-source="dataSource"
            :pagination="false"
            :loading="loading"
          >
            <template slot="action" slot-scope="record">
              <a-button
                type="link"
                @click="
                  $jump({
                    path: '/serviceItems',
                    query: {
                      bcid: record.bcid,
                      id: record.categoryId,
                      name: record.catename,
                      tabIndex:tabIndex
                    }
                  })
                "
              >
                服务项目
              </a-button>
              <a-button
                type="link"
                v-if="tabIndex == 0"
                @click="
                  $jump({
                    path: '/serviceAddress',
                    query: {
                      bcid: record.bcid,
                      id: record.categoryId,
                      name: record.catename
                    }
                  })
                "
              >
                服务地址栅栏
              </a-button>
            </template>
          </my-table>
          <!-- </div> -->
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '',
      tabIndex: 0,
      tabs: [
        {
          key: 0,
          name: '主线业务',
          dataSource: [],
          loading: true,
          pagination: {
            current: 1,
            total: 0
          }
        },
        // {
        //   key: 1,
        //   name: '城市单品业务管理',
        //   dataSource: [],
        //   loading: true,
        //   pagination: {
        //     current: 1,
        //     total: 0
        //   }
        // }
      ],
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '服务类型',
          dataIndex: 'catename'
        },
        {
          title: '操作',
          fixed: 'right',
          width: 300,
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading: true
    }
  },
  created() {
    this.title = this.$getPageTitle()
    this.getMainServiceConfig()
  },
  methods: {
    callback(key) {
      // tab切换
      this.tabIndex = key
      if (this.tabIndex == 0) {
        this.getMainServiceConfig()
      } else {
        this.getBuserving()
      }
    },
    getMainServiceConfig() {
      this.$axios.getMainServiceConfig().then((res) => {
        const v = res.data.data
        // v.forEach((e, i) => {
        //   e.data = []
        //   this.getMainService(e.id, i)
        // })
        this.dataSource = v
        console.log('v', this.dataSource)
        this.loading = false
      })
    },
    getBuserving() {
      const data = {
        categoryId: 3,
        bcid: 3,
        areaId: 3
      }
      this.$axios.getBuserving(data).then((res) => {
        console.log('ddddddddd', res.data)
        const v = res.data.data
        this.dataSource = v
        console.log('v', this.dataSource)
        this.loading = false
      })
    },
    getMainService(id, index) {
      // 获取单品主线服务类型1
      const data = {
        bcid: id
      }
      this.$axios.getMainService(data).then((res) => {
        this.dataSource[index].data = res.data.data
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
.main-title {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  background-color: #fff;
  padding: 12px 32px 0 12px;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.business{
  position: absolute;
  top: 10px;
  right: 20px;
}
</style>
